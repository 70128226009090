import { EgretLoadable } from "egret";
import ConstantList from "../../../appConfig";
import { useTranslation, withTranslation, Trans } from 'react-i18next';
const AmountOfAssetsGroupedByProductCategoryV2 = EgretLoadable({
  //loader: () => import("./BsTableExample")
  loader: () => import("./AmountOfAssetsGroupedByProductCategoryV2")
  //loader: () => import("./AdazzleTable")
  //loader: () => import("./React15TabulatorSample")
});
const ViewComponent = withTranslation()(AmountOfAssetsGroupedByProductCategoryV2);

const AmountOfAssetsGroupedByProductCategoryRoutes = [
  {
    path:  ConstantList.ROOT_PATH + "summary_report/amount_of_assets_grouped_by_product_category",
    exact: true,
    component: ViewComponent
  }
];

export default AmountOfAssetsGroupedByProductCategoryRoutes;
