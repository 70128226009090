import { EgretLoadable } from "egret";
import ConstantList from "../../../appConfig";
import { useTranslation, withTranslation, Trans } from 'react-i18next';
const ReportIncreaseOrDecreaseAssets = EgretLoadable({
  //loader: () => import("./BsTableExample")
  loader: () => import("./ReportIncreaseOrDecreaseAssets")
  //loader: () => import("./AdazzleTable")
  //loader: () => import("./React15TabulatorSample")
});
const ViewComponent = withTranslation()(ReportIncreaseOrDecreaseAssets);

const ReportIncreaseOrDecreaseAssetsRoutes = [
  {
    path:  ConstantList.ROOT_PATH + "summary_report/report_increase_or_decreaseAssets",
    exact: true,
    component: ViewComponent
  }
];

export default ReportIncreaseOrDecreaseAssetsRoutes;
