import { EgretLoadable } from "egret";
import ConstantList from "../../appConfig";
import { withTranslation } from 'react-i18next';
const MedicalEquipmentType = EgretLoadable({
  loader: () => import("./MedicalEquipmentType")
});
const ViewComponent = withTranslation()(MedicalEquipmentType);

const InstrumentAndToolsTypeRoutes = [
  {
    path: ConstantList.ROOT_PATH + "list/medicalEquipmentType",
    exact: true,
    component: ViewComponent
  }
];

export default InstrumentAndToolsTypeRoutes;
