import { EgretLoadable } from "egret";
import ConstantList from "../../../appConfig";
import { useTranslation, withTranslation, Trans } from 'react-i18next';
const AmountOfAssetsGroupedByDepartment = EgretLoadable({
  //loader: () => import("./BsTableExample")
  loader: () => import("./AmountOfAssetsGroupedByDepartment")
  //loader: () => import("./AdazzleTable")
  //loader: () => import("./React15TabulatorSample")
});
const ViewComponent = withTranslation()(AmountOfAssetsGroupedByDepartment);

const AmountOfAssetsGroupedByDepartmentRoutes = [
  {
    path:  ConstantList.ROOT_PATH + "summary_report/amount_of_assets_grouped_by_department",
    exact: true,
    component: ViewComponent
  }
];

export default AmountOfAssetsGroupedByDepartmentRoutes;
