import { EgretLoadable } from "egret";
import ConstantList from "../../appConfig";
import { withTranslation } from 'react-i18next';
const ShoppingForm = EgretLoadable({
  loader: () => import("./ShoppingForm")
});
const ViewComponent = withTranslation()(ShoppingForm);

const ShoppingFormRoutes = [
  {
    path: ConstantList.ROOT_PATH + "list/shoppingForm",
    exact: true,
    component: ViewComponent
  }
];

export default ShoppingFormRoutes;
