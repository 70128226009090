import { EgretLoadable } from "egret";
import ConstantList from "../../appConfig";
import { useTranslation, withTranslation, Trans } from 'react-i18next';
const AssetTransferToAnotherUnitTable = EgretLoadable({
  //loader: () => import("./BsTableExample")
  loader: () => import("./AssetTransferToAnotherUnitTable")
  //loader: () => import("./AdazzleTable")
  //loader: () => import("./React15TabulatorSample")
});
const ViewComponent = withTranslation()(AssetTransferToAnotherUnitTable);

const AssetTransferToAnotherUnitRoutes = [
  {
    path:  ConstantList.ROOT_PATH + "asset/asset_transfer_to_another_unit",
    exact: true,
    component: ViewComponent
  }
];

export default AssetTransferToAnotherUnitRoutes;