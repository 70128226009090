import { EgretLoadable } from "egret";
import ConstantList from "../../appConfig";
import { useTranslation, withTranslation, Trans } from 'react-i18next';
const AssetAllocationTable = EgretLoadable({
  //loader: () => import("./BsTableExample")
  loader: () => import("./AssetAllocationTable")
  //loader: () => import("./AdazzleTable")
  //loader: () => import("./React15TabulatorSample")
});
const ViewComponent = withTranslation()(AssetAllocationTable);

const AssetAllocationRoutes = [
  {
    path:  ConstantList.ROOT_PATH + "asset/allocation_asset",
    exact: true,
    component: ViewComponent
  }
];

export default AssetAllocationRoutes;
