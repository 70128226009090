import { EgretLoadable } from "egret";
import ConstantList from "../../../appConfig";
import { useTranslation, withTranslation, Trans } from 'react-i18next';
const AggregateAssetsByAssetGroup = EgretLoadable({
  loader: () => import("./AggregateAssetsByAssetGroup")
});
const ViewComponent = withTranslation()(AggregateAssetsByAssetGroup);

const AggregateAssetsByAssetGroupRoutes = [
  {
    path:  ConstantList.ROOT_PATH + "summary_report/aggregate_assets_by_asset_group",
    exact: true,
    component: ViewComponent
  }
];

export default AggregateAssetsByAssetGroupRoutes;
