import { EgretLoadable } from "egret";
import ConstantList from "../../appConfig";
import { useTranslation, withTranslation, Trans } from 'react-i18next';
const ProductAttributeValueTable = EgretLoadable({
  //loader: () => import("./BsTableExample")
  loader: () => import("./ProductAttributeValueTable")
  //loader: () => import("./AdazzleTable")
  //loader: () => import("./React15TabulatorSample")
});
const ViewComponent = withTranslation()(ProductAttributeValueTable);

const ProductAttributeValueRoutes = [
  {
    path:  ConstantList.ROOT_PATH + "list/product_attribute_value",
    exact: true,
    component: ViewComponent
  }
];

export default ProductAttributeValueRoutes;
