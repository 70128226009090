import { EgretLoadable } from "egret";
import ConstantList from "../../appConfig";
import { useTranslation, withTranslation, Trans } from 'react-i18next';
const InventoryCountStatusTable = EgretLoadable({
  loader: () => import("./InventoryCountStatusTable")
});
const ViewComponent = withTranslation()(InventoryCountStatusTable);

const InventoryCountStatusRoutes = [
  {
    path:  ConstantList.ROOT_PATH + "asset/inventory_count_status",
    exact: true,
    component: ViewComponent
  }
];

export default InventoryCountStatusRoutes;
