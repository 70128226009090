import { EgretLoadable } from "egret";
import ConstantList from "../../appConfig";
import { withTranslation } from 'react-i18next';
const AssetGroupTable = EgretLoadable({
  //loader: () => import("./BsTableExample")
  loader: () => import("./AssetGroupTable")
  //loader: () => import("./AdazzleTable")
  //loader: () => import("./React15TabulatorSample")
});
const ViewComponent = withTranslation()(AssetGroupTable);

const AssetGroupRoutes = [
  {
    path: ConstantList.ROOT_PATH + "list/asset_group",
    exact: true,
    component: ViewComponent
  }
];

export default AssetGroupRoutes;
