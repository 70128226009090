import { EgretLoadable } from "egret";
import ConstantList from "../../appConfig";
import { useTranslation, withTranslation, Trans } from 'react-i18next';
const AssetAllocationTable = EgretLoadable({
  //loader: () => import("./BsTableExample")
  loader: () => import("./AssetDepreciationCalculatorTable")
  //loader: () => import("./AdazzleTable")
  //loader: () => import("./React15TabulatorSample")
});
const ViewComponent = withTranslation()(AssetAllocationTable);

const AssetDepreciationCalculatorRoutes = [
  {
    path:  ConstantList.ROOT_PATH + "asset/asset_depreciation_calculator",
    exact: true,
    component: ViewComponent
  }
];

export default AssetDepreciationCalculatorRoutes;
