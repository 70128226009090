import React from "react";
import { Redirect } from "react-router-dom";

import dashboardRoutes from "./views/dashboard/DashboardRoutes";
import utilitiesRoutes from "./views/utilities/UtilitiesRoutes";
import sessionRoutes from "./views/sessions/SessionRoutes";
import materialRoutes from "./views/material-kit/MaterialRoutes";
import chartsRoute from "./views/charts/ChartsRoute";
import dragAndDropRoute from "./views/Drag&Drop/DragAndDropRoute";
import invoiceRoutes from "./views/invoice/InvoioceRoutes";
import calendarRoutes from "./views/calendar/CalendarRoutes";
import crudRoute from "./views/CRUD/CrudRoutes";
import inboxRoute from "./views/inbox/InboxRoutes";
import formsRoutes from "./views/forms/FormsRoutes";
import mapRoutes from "./views/map/MapRoutes";
import chatRoutes from "./views/chat-box/ChatRoutes";
import todoRoutes from "./views/todo/TodoRoutes";
import pageLayoutRoutes from "./views/page-layouts/PageLayoutRoutees";
import ListRoute from "./views/list/ListRoute";
import administrativeUnitRoutes from "./views/AdministrativeUnit/AdministrativeUnitRoutes";
import eqaPlanningRoutes from "./views/EQAPlanning/EQAPlanningRoutes";
import datatablePageRootes from "./views/MDBDataTable/DatatablePageRootes";
import tablePageRoutes from "./views/MDBDataTable/TablePageRoutes";
import productRoute from "./views/Product/ProductRoutes";
import productTypeRoutes from "./views/ProductType/ProductTypeRoutes";
import InstrumentAndToolsTypeRoutes from "./views/InstrumentAndToolsType/InstrumentAndToolsTypeRoutes";
import InstrumentToolsListRoutes from "./views/InstrumentToolsList/InstrumentToolsListRoutes";
import productCategoryRoute from "./views/ProductCategory/ProductCategoryRoutes";
import qualificationRoutes from "./views/Qualification/QualificationRoutes";
import productAttributeRoutes from "./views/ProductAttribute/ProductAttributeRoutes";
import reagentRoutes from "./views/Reagent/ReagentRoutes";
import healthOrgLevelRoutes from "./views/HealthOrgLevel/HealthOrgLevelRoutes";
import homeRoutes from "./views/home/HomeRoutes";
import otherRoutes from "./views/others/OtherRoutes";
import scrumBoardRoutes from "./views/scrum-board/ScrumBoardRoutes";
import ecommerceRoutes from "./views/ecommerce/EcommerceRoutes";
import userRoutes from "./views/User/UserRoutes";
import departmentRoutes from "./views/Department/DepartmentRoutes";
import roleRoutes from "./views/Role/RoleRoutes";
import ConstantList from "./appConfig";
import CommonObjectTypeRoutes from "./views/CommonObjectType/CommonObjectTypeRoutes";
import CommonObjectRoutes from "./views/CommonObject/CommonObjectRoutes";
import SupplierRoutes from "./views/Supplier/SupplierRoutes";
import ContractRoutes from "./views/Contract/ContractRoutes";
import AssetSourceRoutes from "./views/AssetSource/AssetSourceRoutes";
import StockKeepingUnitRoutes from "./views/StockKeepingUnit/StockKeepingUnitRoutes";
import AssetTypeRoutes from "./views/AssetType/AssetTypeRoutes";
import AssetGroupRoutes from "./views/AssetGroup/AssetGroupRoutes";
import AssetAllocationRoutes from "./views/AssetAllocation/AssetAllocationRoutes";
import AssetStatusRoutes from "./views/AssetStatus/AssetStatusRoutes";
import ProductAttributeValueRoutes from "./views/ProductAttributeValue/ProductAttributeValueRoutes";
import ProductTemplateRoutes from "./views/ProductTemplate/ProductTemplateRoutes";
import AssetRoutes from "./views/Asset/AssetRoutes";
import MaintainRequestStatusRoutes from "./views/MaintainRequestStatus/MaintainRequestStatusRoutes";
import AssetTransferRoutes from "./views/AssetTransfer/AssetTransferRoutes";
import MaintainRequestRoutes from "./views/MaintainResquest/MaintainRequestRoutes";
import InventoryCountVoucherRoutes from "./views/InventoryCountVoucher/InventoryCountVoucherRoutes";
import InventoryCountStatus  from "./views/InventoryCountStatus/InventoryCountStatusRoutes";
import AggregateAssetsByAssetGroupRoutes  from "./views/SummaryReport/AggregateAssetsByAssetGroup/AggregateAssetsByAssetGroupRoutes";
import AmountOfAssetsGroupedByAssetGroupRoutes  from "./views/SummaryReport/AmountOfAssetsGroupedByAssetGroup/AmountOfAssetsGroupedByAssetGroupRoutes";
import AssetDepreciationCalculatorRoutes from "./views/AssetDepreciationCalculator/AssetDepreciationCalculatorRoutes";
import StaffRoutes from "./views/Staff/StaffRoutes";
import StoreRoutes from "./views/Store/StoreRoutes";
import InventoryReceivingVoucherRoutes from "./views/InventoryReceivingVoucher/InventoryReceivingVoucherRoutes";
import InventoryDeliveryVoucherRoutes from "./views/InventoryDeliveryVoucher/InventoryDeliveryVoucherRoutes";
import StockSummaryReportRoutes from "./views/StockSummaryReport/StockSummaryReportRoutes";
import InventoryReportRoutes from "./views/InventoryReport/InventoryReportRoutes";
import ReceivingAssetRoutes from "./views/ReceivingAsset/ReceivingAssetRoutes";
import PurchaseRequestStatusRoutes from "./views/PurchaseRequestStauts/PurchaseRequestStautsRoutes";
import PurchaseRequestRoutes from "./views/PurchaseRequest/PurchaseRequestRoutes";
import PurchasePlaningRoutes from "./views/PurchasePlaning/PurchasePlaningRoutes";
import MenuRoutes from "./views/Menus/MenuRoutes";
import UserRouterV2 from "./views/UserV2/UserRoutes";
import PurchaseRequestCountRoutes from "./views/PurchaseRequestCount/PurchaseRequestCountRoutes";
import SuppliesPurchaseRequestRoutes from "./views/SuppliesPurchaseRequest/SuppliesPurchaseRequestRoutes";
import SuppliesPurchaseRequestCountRoutes from "./views/SuppliesPurchaseRequestCount/SuppliesPurchaseRequestCountRoutes";
import SuppliesPurchasePlaningRoutes from "./views/SuppliesPurchasePlaning/SuppliesPurchasePlaningRoutes";
import AmountOfAssetsGroupedByDepartmentRoutes  from "./views/SummaryReport/AmountOfAssetsGroupedByDepartment/AmountOfAssetsGroupedByDepartmentRoutes";
import AmountOfAssetsGroupedByProductCategoryRoutes  from "./views/SummaryReport/AmountOfAssetsGroupedByProductCategory/AmountOfAssetsGroupedByProductCategoryRoutes";
import ReportIncreaseOrDecreaseAssetsRoutes  from "./views/SummaryReport/ReportIncreaseOrDecreaseAssets/ReportIncreaseOrDecreaseAssetsRoutes";
import DecreaseInReportedAssetsRoutes  from "./views/SummaryReport/DecreaseInReportedAssets/DecreaseInReportedAssetsRoutes";
import IncreaseInReportedAssetsRoutes  from "./views/SummaryReport/IncreaseInReportedAssets/IncreaseInReportedAssetsRoutes";
import AssetLiquidateRoutes from "./views/AssetLiquidate/AssetLiquidateRoutes";
import AllocationStatusRoutes from "./views/AllocationStatus/AllocationStatusRoutes";
import OrganizationRoutes from "./views/Organization/OrganizationRoutes";
import AssetTransferStatusRoutes from "./views/AssetTransferStatus/AssetTransferStatusRoutes";
import increaseOrDecreaseInReportedAssetsRoutes from "./views/SummaryReport/IncreaseOrDecreaseInReportedAssets/IncreaseOrDecreaseInReportedAssetsRoutes";
import AssetTransferToAnotherUnitRoutes from "./views/AssetTransferToAnotherUnit/AssetTransferToAnotherUnitRoutes";
import MaintainPlaningRoutes from "./views/MaintainPlaning/MaintainPlaningRoutes";
import AssetNoteRoutes from "./views/AssetNote/AssetNoteRoutes";
import InforOrganizationRouter from "./views/InforOrganization/InforOrganizationRouter";
import MedicalEquipmentTypeRoutes from "./views/MedicalEquipmentType/MedicalEquipmentTypeRoutes";
import ShoppingFormRoutes from "./views/ShoppingForm/ShoppingFormRoutes";
import IntrumentsToolsAllocationRoutes from "./views/InstrumentsToolAllocation/InstrumentsToolsAllocationRoutes";
import InstrumentToolsTransferRoutes from "./views/InstrumentToolsTransfer/InstrumentToolsTransferRoutes";

const redirectRoute = [
  {
    path: ConstantList.ROOT_PATH,
    exact: true,
    component: () => <Redirect to={ConstantList.HOME_PAGE} />//Luôn trỏ về HomePage được khai báo trong appConfig
  }
];

const errorRoute = [
  {
    component: () => <Redirect to={ConstantList.ROOT_PATH + "session/404"} />
  }
];

const routes = [
  ...homeRoutes,
  ...AssetNoteRoutes,
  ...increaseOrDecreaseInReportedAssetsRoutes,
  ...DecreaseInReportedAssetsRoutes,
  ...IncreaseInReportedAssetsRoutes,
  ...sessionRoutes,
  ...dashboardRoutes,
  ...administrativeUnitRoutes,
  ...datatablePageRootes,
  ...tablePageRoutes,
  ...materialRoutes,
  ...utilitiesRoutes,
  ...healthOrgLevelRoutes,
  ...chartsRoute,
  ...dragAndDropRoute,
  ...calendarRoutes,
  ...invoiceRoutes,
  ...crudRoute,
  ...inboxRoute,
  ...formsRoutes,
  ...mapRoutes,
  ...chatRoutes,
  ...todoRoutes,
  ...productRoute,
  ...productTypeRoutes,
  ...InstrumentAndToolsTypeRoutes,
  ...CommonObjectTypeRoutes,
  ...AssetSourceRoutes,
  ...ContractRoutes,
  ...CommonObjectRoutes,
  ...SupplierRoutes,
  ...productCategoryRoute,
  ...StockKeepingUnitRoutes,
  ...AssetTypeRoutes,
  ...AssetGroupRoutes,
  ...AssetStatusRoutes,
  ...AssetAllocationRoutes,
  ...AssetRoutes,
  ...scrumBoardRoutes,
  ...ecommerceRoutes,
  ...pageLayoutRoutes,
  ...otherRoutes,
  ...ListRoute,
  ...departmentRoutes,
  ...reagentRoutes,
  ...qualificationRoutes,
  ...productAttributeRoutes,
  ...ProductTemplateRoutes,
  ...eqaPlanningRoutes,
  ...userRoutes,
  ...roleRoutes,
  ...redirectRoute,
  ...AssetTransferRoutes,
  ...MaintainRequestStatusRoutes,
  ...MaintainRequestRoutes,
  ...InventoryCountVoucherRoutes,
  ...InventoryCountStatus,
  ...AggregateAssetsByAssetGroupRoutes,
  ...AmountOfAssetsGroupedByAssetGroupRoutes,
  ...AssetDepreciationCalculatorRoutes,
  ...StaffRoutes,
  ...StoreRoutes,
  ...InventoryReceivingVoucherRoutes,
  ...InventoryDeliveryVoucherRoutes,
  ...StockSummaryReportRoutes,
  ...InventoryReportRoutes,
  ...ReceivingAssetRoutes,
  ...PurchaseRequestStatusRoutes,
  ...PurchaseRequestRoutes,
  ...PurchasePlaningRoutes,
  ...MenuRoutes,
  ...UserRouterV2,
  ...OrganizationRoutes,
  ...PurchaseRequestCountRoutes,
  ...SuppliesPurchaseRequestRoutes,
  ...SuppliesPurchaseRequestCountRoutes,
  ...SuppliesPurchasePlaningRoutes,
  ...AmountOfAssetsGroupedByDepartmentRoutes,
  ...AmountOfAssetsGroupedByProductCategoryRoutes,
  ...ReportIncreaseOrDecreaseAssetsRoutes,
  ...AssetLiquidateRoutes,
  ...AssetTransferToAnotherUnitRoutes,
  ...AllocationStatusRoutes,
  ...AssetTransferStatusRoutes,
  ...MaintainPlaningRoutes,
  ...InforOrganizationRouter,
  ...MedicalEquipmentTypeRoutes,
  ...ShoppingFormRoutes,
  ...InstrumentToolsListRoutes,
  ...IntrumentsToolsAllocationRoutes,
  ...InstrumentToolsTransferRoutes,
  ...errorRoute
];

export default routes;
