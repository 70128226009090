import { EgretLoadable } from "egret";
import ConstantList from "../../appConfig";
import { withTranslation } from "react-i18next";
const InstrumentToolsList = EgretLoadable({
  loader: () => import("./InstrumentToolsList"),
});
const ViewComponent = withTranslation()(InstrumentToolsList);

const InstrumentToolsListRoutes = [
  {
    path: ConstantList.ROOT_PATH + "list/InstrumentToolsList",
    exact: true,
    component: ViewComponent,
  },
];


export default InstrumentToolsListRoutes;
