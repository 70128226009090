import { EgretLoadable } from "egret";
import ConstantList from "../../../appConfig";
import { useTranslation, withTranslation, Trans } from 'react-i18next';
const AmountOfAssetsGroupedByAssetGroupV2 = EgretLoadable({
  //loader: () => import("./BsTableExample")
  loader: () => import("./AmountOfAssetsGroupedByAssetGroupV2")
  //loader: () => import("./AdazzleTable")
  //loader: () => import("./React15TabulatorSample")
});
const ViewComponent = withTranslation()(AmountOfAssetsGroupedByAssetGroupV2);

const AmountOfAssetsGroupedByAssetGroupRoutes = [
  {
    path:  ConstantList.ROOT_PATH + "summary_report/amount_of_assets_grouped_by_asset_group",
    exact: true,
    component: ViewComponent
  }
];

export default AmountOfAssetsGroupedByAssetGroupRoutes;
