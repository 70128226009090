import { EgretLoadable } from "egret";
import ConstantList from "../../appConfig";
import { withTranslation } from 'react-i18next';
const InstrumentAndToolsType = EgretLoadable({
  loader: () => import("./InstrumentAndToolsType")
});
const ViewComponent = withTranslation()(InstrumentAndToolsType);

const InstrumentAndToolsTypeRoutes = [
  {
    path: ConstantList.ROOT_PATH + "list/InstrumentAndToolsType",
    exact: true,
    component: ViewComponent
  }
];

export default InstrumentAndToolsTypeRoutes;
