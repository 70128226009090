import { EgretLoadable } from "egret";
import ConstantList from "../../appConfig";
import { useTranslation, withTranslation, Trans } from 'react-i18next';
const PurchaseRequestCountTable = EgretLoadable({
  //loader: () => import("./BsTableExample")
  loader: () => import("./PurchaseRequestCountTable")
  //loader: () => import("./AdazzleTable")
  //loader: () => import("./React15TabulatorSample")
});
const ViewComponent = withTranslation()(PurchaseRequestCountTable);

const PurchaseRequestCountRoutes = [
  {
    path:  ConstantList.ROOT_PATH + "list/purchase_request_count",
    exact: true,
    component: ViewComponent
  }
];

export default PurchaseRequestCountRoutes;